<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="productForm"
        ref="productForm"
        class="custom-form pt-8"
        @submit.prevent="submitProductForm"
      >
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :items="containerTypes"
              label="Typ kontenera"
              v-model="product.containerTypeId"
              placeholder="Typ kontenera"
              v-bind="autocompleteAttrs"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              :items="debrisAndAggregateTypes"
              label="Typ odpadu/kruszywa"
              v-model="product.debrisTypeId"
              placeholder="Typ odpadu/kruszywa"
              v-bind="autocompleteAttrs"
              :item-text="getDebrisString"
              :filter="searchDebrisType"
              :multiple="!editingProduct"
            >
              <template #selection="{ item, index:number }">
                <MultiselectChoices
                  :index="number"
                  :label="item.code"
                  :array-length="product.debrisTypeId.length"
                  :visible-items="5"
                />
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              type="number"
              label="Cena netto"
              :rules="[rules.required, rules.money, rules.lowerThan(10000001)]"
              v-model.number="product.netValue"
              placeholder="Wpisz cenę netto [zł]"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="productForm"
      >
        {{ editingProduct ? 'Edytuj' : 'Dodaj' }} produkt
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import MultiselectChoices from '../Elements/MultiselectChoices'
import rules from '../../utils/validators'
import { parseAsBasicUnit, searchDebrisType, getDebrisString } from '../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    MultiselectChoices,
  },
  props: {
    editingProduct: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      rules,
      addressId: null,
      product: {
        containerTypeId: null,
        debrisTypeId: null,
        netValue: null,
      },
      autocompleteAttrs: {
        itemText: 'name',
        itemValue: 'id',
        outlined: true,
        dense: true,
        rules: [rules.required]
      }
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.client.isProcessing,
      containerTypes: state => state.containerTypes.items,
      debrisTypes: state => state.debrisTypes.items,
      aggregateTypes: state => state.aggregateTypes.items,
      address: state => state.layout.dialog.item,
    }),
    debrisAndAggregateTypes () {
      return [...this.debrisTypes, ...this.aggregateTypes]
        .sort((a, b) => a.name?.localeCompare(b.name))
    },
  },
  mounted () {
    this.getContainerTypes()
    this.getDebrisTypes({ params: { aggregate: false } })
    this.getAggregateTypes({ params: { endpoint: 'debrisTypes', aggregate: true } })
    if (this.editingProduct) {
      const { id, netValue, containerType, debrisType } = this.editingProduct
      this.product = {
        id,
        debrisTypeId: debrisType.id,
        containerTypeId: containerType.id,
        netValue: netValue / 100
      }
    } else {
      this.addressId = this.address.id
    }
  },
  methods: {
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems',
      getDebrisTypes: 'debrisTypes/getItems',
      getAggregateTypes: 'aggregateTypes/getItems',
      createProduct: 'client/createProduct',
      editProduct: 'client/editProduct',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    searchDebrisType,
    getDebrisString,
    submitProductForm () {
      if (this.$refs.productForm.validate()) {
        const netValue = parseAsBasicUnit(this.product.netValue)

        const productCallback = (message) => {
          this.showSnackbar({ message })
          this.closeDialog()
        }

        if (this.editingProduct) {
          this.editProduct({
            ...this.product,
            netValue
          }).then(productCallback(['Zaktualizowano produkt']))
        } else {
          const products = this.product.debrisTypeId.map(debrisTypeId => ({
            ...this.product,
            netValue,
            debrisTypeId
          }))
          this.createProduct({ products, addressId: this.addressId })
            .then(productCallback(['Dodano produkt']))
        }
      }
    }
  }
}
</script>
